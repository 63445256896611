port module Main exposing
    ( Flags
    , Model
    , Msg
    , SubModel
    , main
    )

import Admin.Home as Home
import Admin.Login as Login
import Admin.Menu as Menu
import Admin.Routes as Routes
import Admin.Types as Types
import Admin.Utils.GraphQL
import Browser exposing (Document)
import Browser.Navigation as Nav
import Cmd.Extra as Cmd
import Css
import Css.Global
import Date exposing (Date)
import Dict.Any
import Graphql.Http as GH
import Html.Styled exposing (Html, div, text)
import Http
import List.Extra as List
import Maybe.Extra as Maybe
import Process
import RemoteData as RD
import Svg.Styled.Attributes as Attr
import Tailwind.Theme as Theme
import Tailwind.Utilities as Tw
import Task
import Time
import Url



-- MODEL


type alias Flags =
    { now : Int }


type SubModel
    = HomeModel Home.Model
    | LoginModel Login.Model


type alias Model =
    { key : Nav.Key
    , page : Routes.Page
    , subModel : SubModel
    , url : Url.Url
    , menu : Menu.Model
    , now : Int
    , user : Maybe Types.User
    }


graphqlEndpoint : String
graphqlEndpoint =
    "http://localhost:4000"


init : Flags -> Url.Url -> Nav.Key -> ( Model, Cmd Msg )
init flags url key =
    let
        route =
            Routes.fromUrl url

        ( menuModel, menuCmd ) =
            Menu.init

        model =
            { page = route
            , subModel = HomeModel { key = key, graphqlEndpoint = graphqlEndpoint }
            , key = key
            , now = flags.now
            , url = url
            , menu = menuModel
            , user = Nothing
            }
    in
    initPage route key model url



-- UPDATE


type Msg
    = LinkClicked Browser.UrlRequest
    | UrlChanged Url.Url
    | GotLoginMsg Login.Msg
    | GotHomeMsg Home.Msg
    | MenuMsg Menu.Msg
    | GotLoginStatus Types.LoginStatus


initPage : Routes.Page -> Nav.Key -> Model -> Url.Url -> ( Model, Cmd Msg )
initPage page navKey model url =
    case page of
        Routes.Home ->
            Home.init navKey graphqlEndpoint
                |> updateWith HomeModel GotHomeMsg model

        Routes.Login ->
            Login.init
                |> updateWith LoginModel GotLoginMsg model


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case ( msg, model.subModel ) of
        ( LinkClicked urlRequest, _ ) ->
            case urlRequest of
                Browser.Internal url ->
                    ( { model | page = Routes.fromUrl url, url = url }, Nav.pushUrl model.key (Url.toString url) )

                Browser.External href ->
                    ( model, Nav.load href )

        ( UrlChanged url, _ ) ->
            let
                page =
                    Routes.fromUrl url

                ( sm, subCmd ) =
                    initPage page model.key model url
            in
            ( sm, subCmd )

        ( GotHomeMsg subMsg, HomeModel subModel ) ->
            let
                ( m, subCmd ) =
                    Home.update subMsg subModel
                        |> updateWith HomeModel GotHomeMsg model
            in
            ( m, subCmd )

        ( GotLoginMsg subMsg, LoginModel subModel ) ->
            let
                ( m, subCmd ) =
                    Login.update subMsg subModel
                        |> updateWith LoginModel GotLoginMsg model
            in
            ( m, subCmd )

        ( MenuMsg subMsg, _ ) ->
            let
                ( m, subCmd ) =
                    Menu.update subMsg model.menu
            in
            ( { model | menu = m }, subCmd )

        ( GotLoginStatus response, _ ) ->
            if response.loggedIn then
                {--Handle logged in user here --}
                let
                    setUser : Types.User
                    setUser =
                        { userId = response.userId
                        , username = response.username
                        , firstname = Nothing
                        , lastname = Nothing
                        }
                in
                case model.page of
                    Routes.Login ->
                        ( { model | user = Just setUser }, Nav.pushUrl model.key "home" )

                    _ ->
                        ( { model | user = Just setUser }, Cmd.none )

            else
                ( { model | user = Nothing }, Nav.pushUrl model.key "login" )

        _ ->
            ( model, Cmd.none )


updateWith : (subModel -> SubModel) -> (subMsg -> Msg) -> Model -> ( subModel, Cmd subMsg ) -> ( Model, Cmd Msg )
updateWith toModel toMsg model ( subModel, subCmd ) =
    ( { model | subModel = toModel subModel }, Cmd.map toMsg subCmd )



-- SUBSCRIPTIONS


port loginStatus : (Types.LoginStatus -> msg) -> Sub msg


subscriptions : Model -> Sub Msg
subscriptions model =
    let
        subModelSubscriptions =
            case model.subModel of
                LoginModel login ->
                    Sub.none

                HomeModel home ->
                    Sub.none
    in
    Sub.batch
        [ loginStatus GotLoginStatus
        , subModelSubscriptions
        ]



-- VIEW


view : Model -> Document Msg
view model =
    { title = "Applink Admin"
    , body =
        [ Html.Styled.toUnstyled <|
            Css.Global.global
                (List.concat
                    [ Tw.globalStyles
                    , [ Css.Global.selector "body" [ Tw.bg_color Theme.white, Tw.font_sans ]
                      ]
                    ]
                )
        , Html.Styled.toUnstyled <| viewBody model
        ]
    }


viewBody : Model -> Html Msg
viewBody model =
    viewApp model


viewApp : Model -> Html Msg
viewApp model =
    let
        content =
            case model.subModel of
                HomeModel subModel ->
                    Html.Styled.map GotHomeMsg (Home.view subModel)

                LoginModel subModel ->
                    Html.Styled.map GotLoginMsg (Login.view subModel)
    in
    div [ Attr.css [] ]
        [ case model.user of
            Just _ ->
                div
                    [ Attr.css
                        [ Tw.grid
                        , Tw.absolute
                        , Tw.inset_0
                        , Css.property "grid-template-columns" "12rem 1fr"
                        ]
                    ]
                    [ div [ Attr.css [ Tw.w_48 ] ]
                        [ Menu.view
                            { page = model.page
                            , url = model.url
                            , user = model.user
                            }
                            model.menu
                            |> Html.Styled.map MenuMsg
                        ]
                    , div
                        [ Attr.css
                            [ Tw.flex_grow
                            , Tw.relative
                            , Tw.h_screen
                            ]
                        ]
                        [ div
                            [ Attr.css
                                [ Tw.flex
                                , Tw.h_full
                                ]
                            ]
                            [ content ]
                        ]
                    ]

            Nothing ->
                case model.subModel of
                    LoginModel subModel ->
                        Html.Styled.map GotLoginMsg (Login.view subModel)

                    _ ->
                        div [] [ text "Loading..." ]
        ]



-- MAIN


main : Program Flags Model Msg
main =
    Browser.application
        { init = init
        , view = view
        , update = update
        , subscriptions = subscriptions
        , onUrlChange = UrlChanged
        , onUrlRequest = LinkClicked
        }
