import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';
import 'elm-keyboard-shortcut';
import ls from 'localstorage-slim';
import * as fb from 'firebase/app';
import * as fbAnalytics from 'firebase/analytics';
import * as fbAuth from 'firebase/auth';

const STORAGE_KEY_LAST_USED_TENANT_ID = 'STORAGE_KEY_LAST_USED_TENANT_ID';
const LOCAL_STORAGE_KEY = 'LOCAL_STORAGE_KEY';

const firebaseConfig = {
    apiKey: 'AIzaSyBBU6icRboSKwp1Vb6o6iPT4zODzieYO3I',
    authDomain: 'applink-83d2a.firebaseapp.com',
    projectId: 'applink-83d2a',
    storageBucket: 'applink-83d2a.appspot.com',
    messagingSenderId: '520354104555',
    appId: '1:520354104555:web:7aa5e4ee1020cedf61aaae',
    measurementId: 'G-V6V2B1NQMT',
};

// Firebase
const fbApp = fb.initializeApp(firebaseConfig);
const analytics = fbAnalytics.getAnalytics(fbApp);
const auth = fbAuth.getAuth();

const app = Elm.Main.init({
    node: document.getElementById('root'),
    flags: {
        now: new Date().valueOf(),
    },
});

app.ports.loginUser.subscribe((credentials) => {
    fbAuth
        .signInWithEmailAndPassword(auth, credentials.username, credentials.password)
        .then((user) => {
            console.log('Logged user in');
            console.log(user);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(error);
        });
});

app.ports.logoutUser.subscribe(() => {
    fbAuth.signOut(auth);
});

fbAuth.onAuthStateChanged(auth, (user) => {
    if (user) {
        console.log('User is still logged in');
        console.log(user);
        app.ports.loginStatus.send({ loggedIn: true, userId: user.uid, username: user.email });
    } else {
        console.log('user is NOT logged in');
        app.ports.loginStatus.send({ loggedIn: false, userId: '', username: '' });
    }
});

//app.ports.localStorageSetValue.subscribe((value) => {
//    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(value));
//});

//app.ports.localStorageGetValues.subscribe(() => {
//    app.ports.localStorageGotValues.send(JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)));
//});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
